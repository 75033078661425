import { Outlet, NavLink } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from '../context/GlobalState';
// import LocalizeSwitch from './components/localizeSwitch';
export default function Home() {
    const { menuBtn, setMenuBtn } = useContext(GlobalContext);
    return (
        <>
            {/* <h1 className="stillinprogress">Home Layout - still in progress</h1> */}

            <div className="main-container">
                <div className="bg-white"></div>

                <header className={`header-wrapper ${menuBtn ? 'open' : ''}`}>
                    <div className="content">
                        <div className="header">
                            <div className="img-container">
                                <img src={process.env.PUBLIC_URL + '/images/kerem1.jpg'} alt="keremunce.com profil resmi" />
                            </div>
                            <div className="text-container">
                                <h4>Kerem Unce</h4>
                                <h6 className="text-sm">Frontend Developer</h6>
                            </div>
                        </div>{/* header-HEADER END*/}

                        <div className="body">
                            <nav>
                                <ul>
                                    <li onClick={() => setMenuBtn(!menuBtn)}><NavLink className="navLink" to="/"><i className="ti-home"></i> Home </NavLink></li>
                                    <li onClick={() => setMenuBtn(!menuBtn)}><NavLink className="navLink" to="about"><i className="ti-user"></i> About </NavLink></li>
                                    <li onClick={() => setMenuBtn(!menuBtn)}><NavLink className="navLink" to="portfolio"><i className="ti-briefcase"></i> Portfolio </NavLink></li>
                                    <li onClick={() => setMenuBtn(!menuBtn)}><NavLink className="navLink" to="contact"><i className="ti-comment"></i>Contact </NavLink></li>
                                </ul>
                            </nav>
                        </div>{/* header-BODY END */}

                        <div className="footer">
                            {/* <LocalizeSwitch></LocalizeSwitch> */}
                            <div className="socials">
                                <div className="box linkedin">
                                    <a href="https://linkedin.com/in/keremunce"><i className="ti-linkedin"></i></a>
                                </div>
                                <div className="box github">
                                    <a href="https://github.com/Keremunce"><i className="ti-github"></i></a>
                                </div>
                            </div>
                            <div className="copyright">
                                <p>© {new Date().getFullYear()} Kerem Unce. All rights reserved.</p>
                            </div>
                        </div>{/* header-FOOTER END */}
                    </div>
                </header> {/* HEADER END */}

                <div onClick={() => setMenuBtn(!menuBtn)} className={`hamburger-menu ${menuBtn ? 'active' : ''}`}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <main className="content">
                    <Outlet></Outlet>
                </main>
            </div>
        </>
    )
}