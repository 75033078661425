import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState"
export default function AboutTab() {
    const { isLoading, setIsLoading } = useContext(GlobalContext);

    const handleCVdownload = () => {
        setIsLoading(true);
        const pdfPath = process.env.PUBLIC_URL + '/downloadableContent/keremunceCV.pdf';

        const link = document.createElement('a');
        link.href = pdfPath;
        link.download = 'keremunceCV.pdf';
        link.click();

        setTimeout(() => {
            setIsLoading(false);
        }, 1000); // Simüle edilen indirme süresi

    }
    return (
        <>
            <section className="AboutTab-container">
                <div>
                    <div className="subtitle">
                        <p><i className="ti-user text-orange-600"></i> Who am I?</p>
                    </div>
                    <div className="title">
                        <h2>About Me</h2>
                    </div>
                    <div className="inner-container">
                        <div className="image-container">
                            <img src={process.env.PUBLIC_URL + '/images/kerem2.jpg'} alt="" />
                        </div>

                        <div className="content-container">
                            <div className="card bio space-y-4">
                                <h6 className="title text-lg font-semibold mb-2">Bio</h6>
                                <p className="desc text-base leading-relaxed">
                                    From an early age, my fascination with computers has driven me to continuously develop my skills in this field. The desire to understand how computers function and to create my own systems has always captivated me. I find particular satisfaction in the process of presenting data in a clear and aesthetically pleasing manner. Creating tangible products that are utilized by others brings me an immense sense of fulfillment.                                </p>
                                <p className="desc text-base leading-relaxed">
                                    I began my career at a startup focused on mobile app development, where I gained firsthand experience in every stage of the mobile app development process. By participating in both technical and marketing meetings, I acquired valuable insights into every aspect of the projects. In my professional life, I am known for my disciplined and positive attitude, and I excel in team environments, working effectively with others to achieve results. I am committed to continuous self-improvement and eager to take on new responsibilities.
                                </p>
                                <p className="desc text-base leading-relaxed">
                                    My favorite project was Manas, which I managed independently. This project marked a first for the company, where I developed a paywall builder system for a mobile application using JavaScript. The system allowed us to generate a JSON output from the input data and live preview the paywall screen created from that output. Additionally, I implemented a feature that enabled the system to be quickly localized into different languages. This project not only saved the company time and money but also set a new standard for internal development.
                                </p>
                                <p className="desc text-base leading-relaxed">
                                    During this process, I also evaluated other paywall builder, analytics, and dashboard services in the market. I identified and addressed the shortcomings I encountered, aiming to create a system that would provide a superior user experience.
                                </p>
                            </div>


                        </div>

                        <div className="downloadCV-container">
                            <span className="download-loader"></span>
                            <button onClick={handleCVdownload} className={`CVdownloadBtn ${isLoading ? 'loading' : ''}`}>{isLoading ? 'Downloading' : 'Download CV'} <i className="ti-download bottom-icon"></i><i className="ti-download top-icon"></i></button>
                        </div>
                        <div className="subcontent-container card info">
                            <div>
                                <p>Birth</p>
                                <p>19/12/2004</p>
                            </div>
                            <div>
                                <p>Email</p>
                                <p>info@keremunce.com</p>
                            </div>
                            <div>
                                <p>Residence</p>
                                <p>Turkey   </p>
                            </div>
                            <div>
                                <p>Phone</p>
                                <p>+90 539 482 90 04</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}