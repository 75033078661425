import { useContext } from "react";
import { GlobalContext } from "../../context/GlobalState";
import emailjs from '@emailjs/browser';

export default function ContactTab() {
    const { form, formData, setFormData, isSending, setIsSending, buttonStatus, setButtonStatus } = useContext(GlobalContext);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setButtonStatus('sending');

        emailjs.sendForm('service_vtu2ae9', 'template_43euvsk', form.current, '8VbdwLVbpo6gJ3h7R')
            .then((result) => {
                console.log(result.text);
                setButtonStatus('success');
                setFormData({
                    form_name: "",
                    user_email: "",
                    subject: "",
                    message: ""
                });
                form.current.reset();
                // Buton durumunu normale döndürmek için kısa bir gecikme ile setTimeout kullanabilirsiniz
                setTimeout(() => setButtonStatus('normal'), 1000); // 3 saniye sonra normale dönecek
            }, (error) => {
                console.error(error.text);
                setButtonStatus('error');
                setTimeout(() => setButtonStatus('normal'), 1000); // 3 saniye sonra normale dönecek
            });
    };

    const contactBoxes = [
        {
            title: 'Phone',
            value: '+90 539 482 90 04',
            icon: 'ti-mobile',
            bgColor: 'rgb(230, 225, 255)',
            color: 'rgb(88, 72, 180)'
        },
        {
            title: 'E-Mail',
            value: 'info@keremunce.com',
            icon: 'ti-email',
            bgColor: 'rgb(255, 217, 201)',
            color: 'rgb(255, 101, 37)'
        },
        {
            title: 'Location',
            value: 'Istanbul/Pendik',
            icon: 'ti-map',
            bgColor: 'rgb(255, 213, 223)',
            color: 'rgb(255, 51, 102)'
        }
    ];

    return (
        <>
            <section className="ContactTab-container">
                <div className="subtitle">
                    <p><i className="ti-comment text-orange-600"></i> Get In Touch</p>
                </div>
                <div className="title">
                    <h2>Contact</h2>
                </div>
                <div className="inner-container">
                    <div className="contactBox-container">
                        {contactBoxes.map((box, index) => (
                            <div key={index} className="contactBox">
                                <div style={{ backgroundColor: box.bgColor, color: box.color }} className="icon-container">
                                    <i className={box.icon}></i>
                                </div>
                                <div>
                                    <p className="title">{box.title}</p>
                                    <p className="subtitle">{box.value}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="form-container">
                        <div className="title">
                            <p>Send Message</p>
                        </div>
                        <form ref={form} onSubmit={handleFormSubmit}>
                            <div className="input-group inputName">
                                <input
                                    name="from_name"
                                    placeholder="Name Surname"
                                    value={formData.from_name}
                                    onChange={handleInputChange}
                                    type="text" />
                            </div>
                            <div className="input-group inputEmail">
                                <input
                                    name="user_email"
                                    placeholder="example@hotmail.com"
                                    value={formData.user_email}
                                    onChange={handleInputChange}
                                    type="email" required />
                            </div>
                            <div className="input-group inputSubject">
                                <input
                                    name="subject"
                                    placeholder="Subject"
                                    value={formData.subject}
                                    onChange={handleInputChange}
                                    type="text" />
                            </div>
                            <div className="input-group inputMessage">
                                <textarea
                                    name="message"
                                    placeholder="Your message here..."
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    type="text" ></textarea>
                            </div>
                            <div className="button-container">
                                <button
                                    type="submit"
                                    className={`p-3 rounded-lg text-white font-bold transition-all duration-300 ease-in-out flex items-center justify-center
                                        ${buttonStatus === 'sending' ? 'bg-blue-500 cursor-not-allowed' :
                                            buttonStatus === 'success' ? 'bg-green-500 hover:bg-green-600' :
                                                buttonStatus === 'error' ? 'bg-red-500 hover:bg-red-600' :
                                                    'bg-orange-500 hover:bg-orange-600'}`}
                                    disabled={buttonStatus === 'sending'}
                                >
                                    {buttonStatus === 'sending' ? (
                                        <>
                                            <i className="ti-reload mr-2 animate-spin"></i> Sending...
                                        </>
                                    ) : buttonStatus === 'success' ? (
                                        <>
                                            <i className="ti-check mr-2"></i> Message Sent
                                        </>
                                    ) : buttonStatus === 'error' ? (
                                        <>
                                            <i className="ti-close mr-2"></i> Failed to Send
                                        </>
                                    ) : (
                                        <>
                                            Send
                                        </>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}
